@font-face {
  font-family: "comic-serif-pro";
  src: url("https://use.typekit.net/af/a0b5a9/000000000000000077359698/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/a0b5a9/000000000000000077359698/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/a0b5a9/000000000000000077359698/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

* {
  font-family: "comic-serif-pro";
  color: white;
}


body {
  width: 100%;
  background-image: url(https://siasky.net/_AJ3jqT9bQKQkhGelAx_nHAaQodRZI6JTEXhJU-mSLrJxw);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /*hide X scrollingbar when animation trigger overflow*/
  overflow-x: hidden;
}
